import React, { useEffect, useState } from "react";
import "./../../css/formField.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const FormField = (props) => {
  const [emp_ref, setempref] = useState([
    { id: 1, sheet: {}, record: "", uploaded: false },
  ]);

  const [sheetData, setSheetData] = useState({});
  const [empData, setEmpData] = useState({});
  const [records, setRecords] = useState({});
  const { id } = useParams();
  const email = id;

  useEffect(() => {
    const fetchEmploymentDetails = async () => {
      try {
        const res = await axios.get(
          `https://api.xhorizons-dev.lexxtechnologies.com/getemps/${email}`
        );
        const evidences = res.data?.data?.employment_details || [];

        const formattedEvidences = evidences.map((evidence, index) => ({
          id: index + 1,
          sheet: {},
          record: evidence.employmentdata.employment_record,
        }));

        setempref(formattedEvidences);
      } catch (error) {
        console.error("Error fetching employment details:", error);
      }
    };

    fetchEmploymentDetails();
  }, [email]);

  const navigate = useNavigate();
  const [data, setData] = useState({
    email_id: "",
    emp_ref: [],
    pay_evi: [],
    others: [], // Initialize as an empty array
    num_uploaded: {},
  });
  

  const addMoreEducationSheet = () => {
    if (emp_ref.length < 5) {
      const newId = emp_ref.length + 1;
      setempref([...emp_ref, { id: newId, sheet: {} }]);
    } else {
      // Optionally, you can show a message or handle this case
      window.alert("Only 5 employment references are accepted.");
    }
  };
  const removeEducationSheet = (id) => {
    if (emp_ref.length === 1) {
      alert("At least one Emp Ref. is mandatory.");
      return;
    }
    setempref(emp_ref.filter((sheet) => sheet.id !== id));
  };
  const onchangeElement = (e) => {
    const { name, value, files } = e.target;
    if (name === "email_id") {
      setData({ ...data, [name]: value });
    } else if (files && files.length > 0) {
      if (name === "others") {
        const otherFiles = Array.from(files); // Convert FileList to array
        setData({ ...data, others: [...data.others, ...otherFiles] });
      } else if (files[0].size > 1e6) {
        alert("File size is more than 1MB. Please upload a file with size less than 1MB.");
        e.target.value = ""; // Clear the file input
        return;
      } else {
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(files[0].type)) {
          alert("Please upload only PDF or Word files.");
          e.target.value = ""; // Clear the file input
          return;
        }
        setData({ ...data, [name]: files[0] });
      }
    }
  };
  
  const onchangeElement1 = (e, index) => {
    if (!e.target.files || !e.target.files[0]) return; // No file selected
  
    const documentType = e.target.getAttribute("documentType");
    if (e.target.files[0].size > 1e6) {
      alert(
        "File size is more than 1MB. Please upload a file with size less than 1MB."
      );
      e.target.value = "";
      return false;
    }
  
    // Check file type
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      alert("Please upload only PDF or Word files.");
      e.target.value = "";
      return;
    }
  
    // Update the state with the file
    const updatedEmpRef = [...emp_ref];
    
    if (documentType === "emp_ref") {
      updatedEmpRef[index].uploadedEmpRef = true;
      setData({ ...data, emp_ref: [...data.emp_ref, e.target.files[0]] });
    } else if (documentType === "pay_evi") {
      const totalFiles =
        data.pay_evi.filter((item) => item.record === index).length +
        e.target.files.length;
      if (totalFiles < 2 || totalFiles > 3) {
        alert("Please upload between 2 to 3 Pay Evidence documents.");
        e.target.value = "";
        return;
      }
      updatedEmpRef[index].uploadedPayEvi = true;
      setData({
        ...data,
        pay_evi: [...data.pay_evi, ...e.target.files],
        num_uploaded: { ...data.num_uploaded, [index]: totalFiles },
      });
    }
  
    // Mark the item as fully uploaded if both are uploaded
    if (updatedEmpRef[index].uploadedEmpRef && updatedEmpRef[index].uploadedPayEvi) {
      updatedEmpRef[index].uploaded = true;
    }
  
    setempref(updatedEmpRef);
  };

  useEffect(() => {
    console.log(data);
    console.log(sheetData);
  }, [data, sheetData]);
  const checkMandatoryFields = () => {
    const mandatoryFields = ["email_id", "emp_ref", "pay_evi"];
    for (let field of mandatoryFields) {
      if (
        !data[field] ||
        (Array.isArray(data[field]) && data[field].length === 0)
      ) {
        alert(`Please fill all mandatory fields.`);
        return false;
      }
    }
    return true;
  };
  const upload = async () => {
    if (!checkMandatoryFields()) {
      return;
    }

    const formData = new FormData();
    formData.append("email_id", data.email_id);

    // Append employee reference files
    data.emp_ref.forEach((file) => {
      if (file) {
        // Check if the file exists
        formData.append("emp_ref", file);
      }
    });

    // Append pay evidence files
    data.pay_evi.forEach((file) => {
      if (file) {
        // Check if the file exists
        formData.append("pay_evi", file);
      }
    });

    // Append number of uploaded files
    formData.append("num_uploaded", JSON.stringify(data.num_uploaded));

    // Append other files
    data.others.forEach((file) => {
      if (file) {
        // Check if the file exists
        formData.append("others", file);
      }
    });

    const settings = {
      method: "POST",
      body: formData,
    };

    try {
      // Log FormData contents for debugging
      formData.forEach((value, key) => {
        console.log(key + ": " + value);
      });

      const fetchResponse = await fetch(
        "https://api.xhorizons-dev.lexxtechnologies.com/uploademp",
        settings
      );

      if (!fetchResponse.ok) {
        const errorData = await fetchResponse.json(); // Get error details from the response
        let errorMessage = `Upload failed. HTTP Status: ${fetchResponse.status}`;
        if (errorData && errorData.detail) {
          // Check for specific error messages
          errorMessage += ` - ${errorData.detail}`;
        }
        alert(errorMessage);
        return; // Stop further processing if the upload fails
      }

      const responseJson = await fetchResponse.json();

      if (responseJson.error) {
        alert(responseJson.error); // Error from Python backend
      } else {
        alert("Files uploaded successfully!");
        navigate("/formsubmitted");
      }
    } catch (error) {
      console.error("Error during upload:", error);
      alert(
        "An error occurred during upload. Please check the console for details."
      );
    }
  };

  return (
    <>
      <div className="form_body">
        <div className="h-context">
          <h3 className="pt-5 form_heading">Upload Documents</h3>
          <ul>
            <li style={{ color: "#0971ce" }}>
              The documents should be clear and colored.
            </li>
            <li style={{ color: "#0971ce" }}>
              Please note that fields marked with an asterisk (*) are mandatory.
            </li>
            <li style={{ color: "#0971ce" }}>
              Please upload files in PDF, PNG, or JPEG format only.
            </li>
            <li style={{ color: "#0971ce" }}>
              Please upload two Pay Evidence for each employment.
            </li>
          </ul>
        </div>
        <form id="form1" className="pb-10" encType="multipart/form-data">
          <div>
            <h3 className="heading-form personal_details mb-5">
              PERSONAL DETAILS
            </h3>
            <div className="grid-container three-by-two"style={{justifyItems:"baseline",}}>
              <div className="first-name personal_details_inputfields">
                <label>
                  Email{" "}
                  <label style={{ color: "red", fontSize: "13px"}}>*</label>
                </label>
                <input onChange={onchangeElement} type="text" name="email_id" />
              </div>
              <div className="last-name personal_details_inputfields">
                {/* <label>
                  {" "}
                  Passport{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="passport"
                /> */}
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Additional Documents
                  {/* <label style={{ color: "red", fontSize: "13px" }}>*</label> */}
                </label>
                <input
                  multiple
                  onChange={onchangeElement}
                  accept=".pdf, image/*"
                  type="file"
                  name="others"
                  documentType="others"
                />
              </div>
              <div className="personal_details_inputfields"></div>
            </div>
          </div>
          
          <div style={{ display: "flex", flexDirection: "column" ,gap:"25px" }}>
              <div
                id="EducationDetail"
                className="filed-area"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "47px",
                  justifyContent: "center",
                  marginBottom: "10px",
                  width: "100%",
                  padding: "2%",
                }}
              ></div>
        {emp_ref.map((item, index) => (
  <div key={index} className="emp_details_inputfields">
    <div style={{ width: "600px" }}>{item.record} : - </div>
    <div
      style={{
        display: "flex",
        width: "102%",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ fontSize: "13px" }}>
            Employment Reference{" "}
            <label style={{ color: "red", fontSize: "13px" }}>
              *
            </label>
          </label>
          <input
            onChange={(e) => onchangeElement1(e, index)}
            accept="application/pdf"
            type="file"
            name={`emp_ref${item.id}`}
            documentType="emp_ref"
            disabled={index > 0 && !emp_ref[index - 1].uploaded} // Disable if previous item not uploaded fully
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ fontSize: "13px" }}>
            Pay Evidence{" "}
            <label style={{ color: "red", fontSize: "13px" }}>
              *
            </label>
          </label>
          <input
            multiple
            onChange={(e) => onchangeElement1(e, index)}
            accept="application/pdf"
            type="file"
            name={`emp_ref${item.id}`}
            documentType="pay_evi"
            disabled={index > 0 && !emp_ref[index - 1].uploaded} // Same check for pay evidence
          />
        </div>
      </div>
      <div
        onClick={() => removeEducationSheet(item.id)}
        style={{
          cursor: "pointer",
          color: "red",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "5px",
        }}
      >
        Remove
      </div>
      
    </div>
    
  </div>
  
  
))}
 <div
              onClick={addMoreEducationSheet}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#0971ce",
                width: "60px",
                height: "40px",
                // borderRadius: "10000px",
                marginTop: "30px",
                alignItems: "center",
                margin: "auto",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              Add
            </div>
          </div>
          <button onClick={upload} className="FormSubmitBtn" type="button">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
export default FormField;
