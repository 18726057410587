import React, { useEffect, useState } from "react";
import "./../../css/formField.css";
import { useNavigate } from "react-router-dom";
const FormField = (props) => {
  const [educationalSheets, setEducationalSheets] = useState([
    { id: 1, sheet: {} },
  ]);
  const [sheetData, setSheetData] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState({
    email_id: "",
    passport: "",
    government_id: "",
    resume: "",
    birth_cert: "",
    name_change_cert: "",
    educational: [],
    rpl_ref_form: "",
    emp_sheet: "",
  });

  const addMoreEducationSheet = () => {
    const newId = educationalSheets.length + 1;
    setEducationalSheets([...educationalSheets, { id: newId, sheet: {} }]);
  };

  const removeEducationSheet = (id) => {
    if (educationalSheets.length === 1) {
      alert("At least one educational document is mandatory.");
      return;
    }
    setEducationalSheets(educationalSheets.filter((sheet) => sheet.id !== id));
  };

  const onchangeElement = (e) => {
    const { name, value, files } = e.target;
    if (name === "email_id") {
      setData({ ...data, [name]: value });
    } else if (files && files[0]) {
      // Check file size
      if (name !== "emp_sheet" && files[0].size > 1e6) {
        alert(
          "File size is more than 1MB. Please upload a file with size less than 1MB."
        );
        e.target.value = ""; // Clear the file input
        return;
      }
      // Check file type
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
      ];
      if (!allowedTypes.includes(files[0].type)) {
        alert("Please upload only PDF or Word files.");
        e.target.value = ""; // Clear the file input
        return;
      }
      setData({ ...data, [name]: files[0] });
    }
  };
  
  const onchangeElement1 = (e) => {
    if (!e.target.files || !e.target.files[0]) {
      return; // No file selected, do nothing
    }
  
    if (e.target.files[0].size > 1e6) {
      alert("File size is more than 1MB. Please upload a file with size less than 1MB.");
      e.target.value = ""; // Clear the file input
      return;
    }
  
    let name = e.target.name;
    let temp = sheetData;
    const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      alert("Please upload only PDF or Word files.");
      e.target.value = ""; // Clear the file input
      return;
    }
    temp[name] = e.target.files[0];
    setSheetData({ ...temp });
  
    // Update the educational array with the new file
    setData({
      ...data,
      educational: [...Object.values(temp)],
    });
  };
  
  

  useEffect(() => {
    console.log(data);
  }, [data]);
  // const upload = async () => {
  //   if (!checkMandatoryFiles()) {
  //     return;
  //   }
  //   const formData = new FormData();
  //   formData.append("email_id", data.email_id);
  //   formData.append("passport", data.passport);
  //   formData.append("government_id", data.government_id);
  //   formData.append("resume", data.resume);
  //   formData.append("birth_cert", data.birth_cert);
  //   formData.append("name_change_cert", data.name_change_cert);

  //   data.educational.forEach((eduDoc) => {
  //     formData.append("educational", eduDoc);
  //   });

  //   if (data.emp_sheet) {
  //     formData.append("emp_sheet", data.emp_sheet);
  //   }

  //   const settings = {
  //     method: "POST",
  //     body: formData,
  //   };

  //   try {
  //     formData.forEach((value, key) => {
  //       console.log(key + " " + value);
  //     });
  //     const fetchResponse = await fetch(
  //       "https://api.xhorizons-dev.lexxtechnologies.com/fileupload",
  //       settings
  //     );
  //     const responseJson = await fetchResponse.json();
  //     if (fetchResponse.ok) {
  //       if (responseJson.error) {
  //         alert(responseJson.error); // Error from Python backend
  //       } else {
  //         // alert("Files uploaded successfully!");
  //         navigate("/formsubmitted");
  //       }
  //     } else {
  //       alert(`Upload failed. HTTP Status: ${fetchResponse.status}`);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     alert(
  //       "An error occurred during upload. Please check the console for details."
  //     );
  //   }
  // };
  const upload = async () => {
    if (!checkMandatoryFiles()) {
      return;
    }
  
    const formData = new FormData();
    formData.append("email_id", data.email_id);
  
    // Simplified appending of files:
    const filesToUpload = {
      "passport": data.passport,
      "government_id": data.government_id,
      "resume": data.resume,
      "birth_cert": data.birth_cert,
      "name_change_cert": data.name_change_cert,  // Optional
      "rpl_ref_form": data.rpl_ref_form,        // Optional
      "emp_sheet": data.emp_sheet             // Optional
    };
  
    for (const key in filesToUpload) {
      if (filesToUpload[key]) { // Check if the optional file exists
        formData.append(key, filesToUpload[key]);
      }
    }
  
    data.educational.forEach((eduDoc, index) => {
      formData.append(`educational`, eduDoc); 
    });
  
    const settings = {
      method: "POST",
      body: formData,
    };
  
  
    try {
      const response = await fetch(
        "https://api.xhorizons-dev.lexxtechnologies.com/fileupload",
        settings
      );
  
      if (!response.ok) {
        const errorData = await response.json(); // Get error details from the response
        let errorMessage = `Upload failed. HTTP Status: ${response.status}`;
        if(errorData && errorData.detail) { // Check for more specific error messages
           errorMessage += ` - ${errorData.detail}`;
        }
        alert(errorMessage);
        return;  // Stop further processing if the upload fails
      }
  
      const responseJson = await response.json();
  
      if (responseJson.error) {
        alert(responseJson.error);
      } else {
        navigate("/formsubmitted");
      }
  
    } catch (error) {
      console.error("Error during upload:", error);
      alert(
        "An error occurred during upload. Please check the console for details."
      );
    }
  };
  
  
  // Helper function to check mandatory files
  const checkMandatoryFiles = () => {
    const mandatoryFields = ["passport", "government_id", "resume", "birth_cert"];
    const missingFiles = mandatoryFields.filter(field => !data[field]);
  
    if (missingFiles.length > 0) {
      alert(`Please upload the following mandatory files: ${missingFiles.join(", ")}`);
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="form_body">
        <div className="h-context">
          <h3 className="pt-5 form_heading">Upload Documents</h3>
          <ul>
            <li style={{ color: "#0971ce" }}>
              The documents should be clear and colored.
            </li>
            <li style={{ color: "#0971ce" }}>
              The photograph in the document should be clearly visible.
            </li>
            <li style={{ color: "#0971ce" }}>
              Please note that fields marked with an asterisk (*) are mandatory.
            </li>
            <li style={{ color: "#0971ce" }}>
              Please upload files in PDF or .docx format only.
            </li>
          </ul>
        </div>
        <form id="form1" className="pb-10" encType="multipart/form-data">
          <div>
            <h3 className="heading-form personal_details mb-5">
              PERSONAL DETAILS
            </h3>
            <div className="grid-container three-by-two">
              <div className="first-name personal_details_inputfields">
                <label>
                  Email{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="text"
                  name="email_id"
                />
              </div>
              <div className="last-name personal_details_inputfields">
                <label>
                  {" "}
                  Passport{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="passport"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Government ID
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="government_id"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  VEVO issued document or ImmiCard (or birth certificate)
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="birth_cert"
                />
              </div>
              <div className="personal_details_inputfields">
                <label> Evidence of Name change/ Marriage Certificate</label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="name_change_cert"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>
                  Resume{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  id="resume"
                  onChange={onchangeElement}
                  accept="application/pdf,.doc,.docx"
                  type="file"
                  name="resume"
                />
              </div>
            </div>
            <div className="form-filed-area-1"></div>
          </div>

          <div>
            <h3
              style={{ marginBottom: "0px" }}
              className="heading-form personal_details "
            >
              EDUCATIONAL DETAILS
            </h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Relevant Documents
              </div>
              <div
                id="EducationDetail"
                className="filed-area"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "47px",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {educationalSheets.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="educational_details_inputfields"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label style={{fontSize:"13px"}} >
                          Certificate{" "}
                          <label style={{ color: "red", fontSize: "13px" }}>
                            *
                          </label>
                        </label>
                        <input
                          onChange={onchangeElement1}
                          accept="application/pdf"
                          type="file"
                          name={`educationsheet${item.id}`}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label style={{fontSize:"13px"}} >
                          Transcript/Marksheet{" "}
                          <label style={{ color: "red", fontSize: "13px" }}>
                            *
                          </label>
                        </label>
                        <input
                          onChange={onchangeElement1}
                          accept="application/pdf"
                          type="file"
                          name={`educationtranscript${item.id}`}
                        />
                      </div>
                      <div
                        onClick={() => removeEducationSheet(item.id)}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={addMoreEducationSheet}
              style={{
                display: "flex",
              justifyContent: "center",
              backgroundColor: "#0971ce",
              width: "60px",
              height: "40px",
              // borderRadius: "10000px",
              marginTop: "30px",
              alignItems: "center",
              margin: "auto",
              color: "white",
              fontWeight: "bolder",
              }}
            >
              Add
            </div>
          </div>
          {/* <div>
            <h3 className="heading-form personal_details mb-5">RPL</h3>
            <div className="filed-area">
              <div style={{width:'35%'}}  className="first-name personal_details_inputfields">
                <label>RPL Reference </label>
                <input
                  className="educational_details_inputfields"
                  onChange={onchangeElement}
                  accept=".doc,.docx"
                  type="file"
                  name="rpl_ref_form"
                />
              </div>
            </div>
          </div> */}
          <div>
            <h3 className="heading-form personal_details mb-5">
              EMPLOYMENT REFERENCE
            </h3>
            <ul>
              <li style={{ color: "red" }}>
                Please upload files in .docx format only
              </li>
            </ul>
            <div className="filed-area">
              <div
                style={{ width: "35%" }}
                className="first-name personal_details_inputfields"
              >
                <label>
                  Employment Sheet{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  className="educational_details_inputfields"
                  onChange={onchangeElement}
                  accept=".doc,.docx"
                  type="file"
                  name="emp_sheet"
                />
              </div>
            </div>
          </div>
          <button onClick={upload} className="FormSubmitBtn" type="button">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default FormField;
